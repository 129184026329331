import React, { useMemo } from 'react'
import { jiraEpicsContributionsTableRequests } from '@src/api/roadmaps'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { JiraEpicInterface } from '@src/interfaces/roadmaps'
import { TableNames } from '@src/constants/table'
import { TableTypes } from '@src/interfaces/table'
import { toIdAndName } from '@src/utils/toIdAndName'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import {
  epicContributionColumn,
  epicNameColumn,
  epicPriorityColumn,
} from '@src/features/AiInsights/Performance/components/Metrics/External/Jira/EpicsContributionsTable/columns'

const row: RowInterface<JiraEpicInterface> = {
  cells: [
    {
      ...epicNameColumn,
      width: 250,
    },
    {
      ...epicPriorityColumn,
      width: 100,
    },
    {
      ...epicContributionColumn,
      width: 200,
    },
  ],
}

interface Props {
  employeeId: number
  cycleId: number
}

export const EpicsContributionsTable = ({ employeeId, cycleId }: Props) => {
  const initialFilters = useMemo<FilterByInterface[]>(
    () => [
      {
        filters: [toIdAndName(String(employeeId))],
        columnName: 'employee_id',
      },
      {
        filters: [toIdAndName(String(cycleId))],
        columnName: 'cycle_id',
      },
    ],
    [employeeId, cycleId],
  )
  const table = useTable(jiraEpicsContributionsTableRequests, initialFilters)

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.JiraEpicsContributions}
          type={TableTypes.Contained}
          dataType="Epic"
          {...table}
          count={table.data.length}
          row={row}
          emptyState={<EmptyTableRaw title="No Contributions found" imageSize={72} />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
