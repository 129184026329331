import React from 'react'
import {
  Grid,
  Avatar,
  HStack,
  Text,
  Icon,
  TextButton,
  Token,
  IconName,
} from '@revolut/ui-kit'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { LinearTaskType } from '@src/interfaces/roadmaps'

// TODO Pending UIKit
// REVC-8086
const iconMap: Record<LinearTaskType, IconName> = {
  Issue: 'CircleProfile',
  Project: 'Target',
}

interface Props {
  label: React.ReactNode
  url?: string
  taskId?: string
  type?: LinearTaskType
  onDelete?: () => void
}

export const LinearSelectOption = ({ label, taskId, url, type, onDelete }: Props) => {
  return (
    <Grid
      columns="max-content max-content 1fr max-content"
      gap="s-8"
      placeContent="center"
      style={{ alignItems: 'center' }}
    >
      {type ? (
        <Icon size={20} name={iconMap[type]} />
      ) : (
        <Avatar
          size={20}
          image={{
            default: 'https://assets.revolut.com/assets/apps/Linear.webp',
            '2x': 'https://assets.revolut.com/assets/apps/Linear@2x.webp',
            '3x': 'https://assets.revolut.com/assets/apps/Linear@3x.webp',
          }}
        />
      )}
      <Text color={Token.color.greyTone50}>{taskId}:</Text>
      <Text>{label}</Text>
      <HStack space="s-16">
        {url && <OpenInNewTab label="" link={url} onClick={e => e.stopPropagation()} />}
        {!!onDelete && (
          <TextButton onClick={onDelete}>
            <Icon size={18} name="Delete" color={Token.color.greyTone20} />
          </TextButton>
        )}
      </HStack>
    </Grid>
  )
}
