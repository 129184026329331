import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Action,
  ActionButton,
  Box,
  chain,
  Color,
  Flex,
  Subheader,
  Token,
} from '@revolut/ui-kit'

import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { EntityPermissions, FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  EmployeeChangeRequestParams,
  EmployeeChangeRequestType,
} from '@src/interfaces/employeeChangeRequest'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { FilterByInterface } from '@src/interfaces/data'
import { getEmployeeChangeRequests } from '@src/api/employeeChangeRequest'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { getEmployeeNotificationStats } from '@src/api/notifications'
import { NotificationsStatisticsInterface } from '@src/interfaces/notifications'
import {
  DocumentSources,
  DocumentStatuses,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { useEmployeeFields } from '@src/api/employees'
import UserWithAvatar, {
  UserWithAvatarProps,
} from '@components/UserWithAvatar/UserWithAvatar'
import { formatWithoutTimezone } from '@src/utils/format'
import { EmployeesDefaultHours } from '@src/interfaces/attendance'
import { useGetEmployeeSettings } from '@src/api/settings'
import {
  getProfileSectionFields,
  getSectionPermissions,
  SectionInterface,
} from '../common'
import {
  EmployeeTimeOffRequestInterface,
  EmployeeTimeOffRequestSummaryInterface,
} from '@src/interfaces/timeOff'
import { useOneToOnesCalendarAvailability } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/hooks/useOneToOnesAvailability'
import { CalendarProviders } from '@src/interfaces/meetings'

export const canViewPersonal = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeespersonalinfo)

export const canViewContracts = (data: EmployeeInterface) =>
  data.field_options?.permissions?.includes(PermissionTypes.ViewEmployeecontract)

export const canViewFindings = (data: EmployeeInterface, enabled: boolean) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeeAudits) &&
  enabled

export const canViewRisk = (data: EmployeeInterface, enabled: boolean) =>
  !!data.field_options.permissions?.includes(EntityPermissions.ViewKri) && enabled

export const canViewRightToWork = (data: EmployeeInterface) =>
  data.employee_type === 'internal' &&
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeerighttowork)

export const canViewOwnership = (data: EmployeeInterface) =>
  data.employee_type === 'internal' &&
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeeOwnershipTab)

export const hasScreeningPermissions = (data: EmployeeInterface) =>
  !!data.field_options?.permissions?.includes(PermissionTypes.ViewScreeningChecks)
export const canViewScreening = (data: EmployeeInterface, enabled: boolean) =>
  enabled && hasScreeningPermissions(data)

export const hasPayrollPermissions = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeepayrolldetails)
export const canViewPayroll = (data: EmployeeInterface, enabled: boolean) =>
  enabled && hasPayrollPermissions(data)

export const canViewKeyPerson = (data: EmployeeInterface, enabled: boolean) =>
  enabled &&
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewKeyRiskProfile)

export const canViewChangelog = (data: EmployeeInterface) =>
  !!data.field_options?.permissions?.includes(PermissionTypes.ViewEmployeechangelogitem)

export const canViewTimeline = (data: EmployeeInterface) =>
  !!data.field_options?.permissions?.includes(PermissionTypes.ViewEmployeetimelineitem)

export const canViewDiversity = (data: EmployeeInterface) =>
  data.employee_type === 'internal' &&
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeediversity)

export const canViewTermination = (data: EmployeeInterface) =>
  data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeetermination)

export const hasOffboardingV2Enabled = (data: EmployeeInterface) =>
  data?.field_options?.permissions?.includes(PermissionTypes.HasOffboardingV2Enabled)

export const hasLinkedAccountsPermissions = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewEmployeelinkedaccount)
export const canViewLinkedAccounts = (data: EmployeeInterface, enabled: boolean) =>
  enabled && hasLinkedAccountsPermissions(data)

export const canViewInbox = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewInbox)

export const canViewPolicyAssignments = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(
    PermissionTypes.ViewTimeOffPolicyAssignments,
  )

export const canViewTimeOffRequests = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewTimeOffRequests)

export const canViewTimeOffBalances = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewTimeOffBalances)

export const canAddTimeOffRequests = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.AddTimeOffRequests)

export const canEditEmployeeTimeOffRequest = (request: EmployeeTimeOffRequestInterface) =>
  request.field_options?.actions?.includes(EntityPermissions.Change) &&
  request.field_options?.permissions?.includes(PermissionTypes.ChangeTimeOffRequest)

export const canApproveEmployeeTimeOffRequest = (
  request: EmployeeTimeOffRequestInterface | EmployeeTimeOffRequestSummaryInterface,
) =>
  request.field_options?.actions?.includes(EntityPermissions.Approve) &&
  request.field_options?.permissions?.includes(PermissionTypes.ApproveTimeOffRequest)

export const canRejectEmployeeTimeOffRequest = (
  request: EmployeeTimeOffRequestInterface | EmployeeTimeOffRequestSummaryInterface,
) =>
  request.field_options?.actions?.includes(EntityPermissions.Reject) &&
  request.field_options?.permissions?.includes(PermissionTypes.RejectTimeOffRequest)

export const canCancelEmployeeTimeOffRequest = (
  request: EmployeeTimeOffRequestInterface,
) =>
  request.field_options?.actions?.includes(EntityPermissions.Cancel) &&
  request.field_options?.permissions?.includes(PermissionTypes.CancelTimeOffRequest)

export const canAddTimeOffAbsences = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.AddTimeOffAbsences)

export const canViewScheduleSettings = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(
    PermissionTypes.ViewEmployeeScheduleSettings,
  )

export const useCanViewMeetingsTab = (data?: EmployeeInterface) => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const oneToOnesCalendarAvailability = useOneToOnesCalendarAvailability()

  const isSelfOrManager =
    user.id === data?.id ||
    user.id === data?.line_manager?.id ||
    user.id === data?.quality_control?.id

  return (
    (isSelfOrManager || permissions.includes(PermissionTypes.ViewMeeting)) &&
    oneToOnesCalendarAvailability[CalendarProviders.Google]
  )
}

export const canAddScheduleSettings = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(
    PermissionTypes.AddEmployeeScheduleSettings,
  )

export const canChangeScheduleSettings = (data?: EmployeesDefaultHours) =>
  !!data?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeScheduleSettings,
  )

export const canViewTimeOff = (
  data: EmployeeInterface | undefined,
  settingEnabled: boolean,
) =>
  settingEnabled &&
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewTimeOff)

export const canViewCompensationV2 = (data?: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewTotalCompensation)

export const canViewDocuments = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewDocuments)

export const canViewBalances = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(PermissionTypes.ViewTimeOffBalances)

export const canAddTimeOffBalanceAdjustments = (data: EmployeeInterface) =>
  !!data?.field_options?.permissions?.includes(
    PermissionTypes.AddTimeOffBalanceAdjustments,
  )

export const canTerminateEmployees = (data: EmployeeInterface) =>
  !!data?.field_options.permissions?.includes(PermissionTypes.CanTerminateEmployees)

export const useEmployeesOverdueInboxStats = (employee: EmployeeInterface) => {
  const [stats, setStats] = useState<NotificationsStatisticsInterface>()

  const canViewEmployeeInbox = !!employee?.field_options?.permissions?.includes(
    PermissionTypes.ViewInbox,
  )

  const refresh = () => {
    if (canViewEmployeeInbox) {
      const now = new Date().toISOString()
      getEmployeeNotificationStats(employee.id, [
        {
          filters: [{ id: `,${now}`, name: 'deadline' }],
          columnName: 'deadline',
        },
      ]).then(response => setStats(response.data))
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  return { stats, refresh }
}

const getFilterForChanges = (section: 'organisation'): FilterByInterface[] => {
  const statusFilter = {
    filters: [
      { id: 'pending_approval', name: 'pending_approval' },
      { id: 'approved', name: 'approved' },
    ],
    columnName: 'status',
  }

  const sectionToFilter: Record<typeof section, FilterByInterface> = {
    organisation: {
      filters: getProfileSectionFields('organisation').map(f => ({
        id: f,
        name: f,
      })),
      columnName: 'field__field_name',
    },
  }

  return [statusFilter, sectionToFilter[section]]
}

const getHeaderButtons = (
  employeeId: string,
  type: Exclude<EmployeeChangeRequestType, 'details'>,
  enabled: boolean,
  section?: SectionInterface,
) => {
  const buttons = []
  const { canRequestChange, canEdit } = getSectionPermissions(enabled, section)

  const editUrls: Record<Exclude<EmployeeChangeRequestType, 'details'>, string> = {
    name: ROUTES.FORMS.EMPLOYEE.GENERAL.NAME,
    organisation: ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION,
    position: ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION,
  }

  if (canRequestChange && !canEdit) {
    buttons.push({
      title: 'Request change',
      onClick: () =>
        navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
            employeeId,
            type,
          } as EmployeeChangeRequestParams),
        ),
    })
  }

  if (canEdit && type in editUrls) {
    buttons.push({
      title: 'Edit',
      onClick: () =>
        navigateTo(
          pathToUrl(editUrls[type], {
            id: employeeId,
          }),
        ),
    })
  }

  return buttons.map(button => (
    <Action onClick={button.onClick} key={button.title}>
      {button.title}
    </Action>
  ))
}

interface useSectionHeaderButtons {
  employeeId: string
  type: Exclude<EmployeeChangeRequestType, 'details'>
}

export const useSectionHeader = ({ employeeId, type }: useSectionHeaderButtons) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false)
  const [canCheckForPendingChanges, setCanCheckForPendingChanges] = useState(false)
  const { data: globalEmployeeSettings } = useGetEmployeeSettings()

  useEffect(() => {
    if (canCheckForPendingChanges && type === 'organisation') {
      getEmployeeChangeRequests(employeeId)({
        filters: getFilterForChanges(type),
      }).then(response => {
        if (response.data.count) {
          setHasPendingChanges(true)
        }
      })
    }
  }, [canCheckForPendingChanges])

  const typeToTitle: Record<Exclude<EmployeeChangeRequestType, 'details'>, string> = {
    name: 'Name',
    organisation: 'Organisation',
    position: 'Position',
  }

  const typeToGlobalSetting = {
    name: !!globalEmployeeSettings?.enable_request_changes_name,
    organisation: !!globalEmployeeSettings?.enable_request_changes_organisation,
    position: !!globalEmployeeSettings?.enable_request_changes_position,
  }

  return {
    onDataLoaded: (section?: SectionInterface) => {
      const { canEdit, canRequestChange } = getSectionPermissions(
        typeToGlobalSetting[type],
        section,
      )
      if (canEdit || canRequestChange) {
        setCanCheckForPendingChanges(true)
      }
    },
    customHeader: (section?: SectionInterface) => {
      const buttons = getHeaderButtons(
        employeeId,
        type,
        typeToGlobalSetting[type],
        section,
      )
      return (
        <Subheader variant="nested">
          <Subheader.Title>{typeToTitle[type]}</Subheader.Title>
          <Subheader.Side>
            <Flex color={Color.FOREGROUND}>{chain(...buttons)}</Flex>
          </Subheader.Side>
        </Subheader>
      )
    },
    hasPendingChanges,
  }
}

interface ChangeRequestsPendingWidgetProps {
  id: number
  isVisible: boolean
}

export const ChangeRequestsPendingWidget = ({
  id,
  isVisible,
}: ChangeRequestsPendingWidgetProps) => {
  if (isVisible) {
    return (
      <Box mb="s-16">
        <ActionWidget
          title="Change request pending"
          text="There is one or more change requests pending or scheduled for this section"
        >
          <ActionButton
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG.EMPLOYEE_INFO, { id }))
            }
          >
            View requests
          </ActionButton>
        </ActionWidget>
      </Box>
    )
  }

  return null
}

export const getDocumentLink = (document: EmployeeDocumentListItemInterface) => {
  if (
    document.source === DocumentSources.Request ||
    document.source === DocumentSources.Upload
  ) {
    return navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_UPLOAD.GENERAL, {
        employeeId: document.employee.id,
        id: document.recipient_id,
      }),
    )
  }

  if (document.source === DocumentSources.Embedded) {
    return navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_EMBEDDED.GENERAL, {
        id: document.recipient_id,
        employeeId: document.employee.id,
      }),
    )
  }

  if (document.source === DocumentSources.Docusign) {
    return navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_DOCUSIGN.GENERAL, {
        id: document.recipient_id,
        employeeId: document.employee.id,
      }),
    )
  }

  return '/'
}

export const getDocumentHighlight = (document: EmployeeDocumentListItemInterface) => {
  if (
    document.status === DocumentStatuses.pending ||
    document.status === DocumentStatuses.pending_upload ||
    document.status === DocumentStatuses.pending_approval
  ) {
    return Token.color.orange_5
  }

  return ''
}

interface EmployeeUserWithAvatarProps extends UserWithAvatarProps {
  id: number | string
}

export const EmployeeUserWithAvatar = ({ id, ...props }: EmployeeUserWithAvatarProps) => {
  const { data: employee, isLoading } = useEmployeeFields<EmployeeOptionInterface>(id, [
    'id',
    'full_name',
    'avatar',
    'status',
  ])

  return <UserWithAvatar {...employee} isLoading={isLoading} {...props} />
}

export const formatDateWithFallback = (date?: string | null, fallback = '') => {
  return date ? formatWithoutTimezone(date) : fallback
}

export const optionalField = (field?: React.ReactNode, suffix?: React.ReactNode) => {
  return field ? (
    <>
      {field}
      {suffix}
    </>
  ) : (
    ''
  )
}

export const useEmployeeRecordToPrimaryContractEnabled = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  return featureFlags.includes(FeatureFlags.EmployeeRecordToPrimaryContract)
}
