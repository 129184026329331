import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  BoxProps,
  DataPoint,
  DataPointSkeleton,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Text,
  Token,
  Tooltip,
  TooltipProps,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import {
  getComparableMetricProps,
  MetricDetailsVariants,
} from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'
import { MetricDetails } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/MetricDetails'
import { formatNumericValue } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/formatters'

interface Props extends Omit<FlexProps, 'summary'> {
  value: number
  average: number
  metricName: string
  orgUnitName: string
  employeeName?: string
  link?: string
  variant?: MetricDetailsVariants
  dataLabel: React.ReactNode
  summary?: React.ReactNode
  tooltipProps?: Partial<TooltipProps>
}

export const MetricHighlight = ({
  value,
  average,
  metricName,
  employeeName,
  orgUnitName,
  link,
  variant = MetricDetailsVariants.Default,
  dataLabel,
  summary,
  tooltipProps = { width: 280, placement: 'left' },
  ...flexProps
}: Props) => {
  const tooltip = useTooltip()
  const [isHighlighted, setIsHighlighted] = useState(tooltip.state)

  const valueFormatted = formatNumericValue(value, variant)
  const averageFormatted = formatNumericValue(average, variant)
  const metricProps = getComparableMetricProps(value, average)

  useEffect(() => {
    if (tooltip.state === isHighlighted) {
      return
    }

    setIsHighlighted(tooltip.state)
  }, [tooltip.state, isHighlighted])

  return (
    <Box
      p="s-16"
      radius={Token.radius.r16}
      bg={isHighlighted ? Token.color.greyTone10 : Token.color.widgetBackground}
      {...flexProps}
    >
      <VStack space="s-4" align="center" {...tooltip.getAnchorProps()}>
        <DataPoint.Label>
          <HStack space="s-4" align="center">
            <Text>{dataLabel}</Text>
            {link && (
              <IconButton
                useIcon="LinkExternal"
                use="a"
                target="_blank"
                href={link}
                size={16}
              />
            )}
          </HStack>
        </DataPoint.Label>
        <DataPoint.Value>
          <HStack space="s-4" align="center">
            {metricProps && <Icon name={metricProps.name} color={metricProps.color} />}
            <Text>{valueFormatted}</Text>
          </HStack>
        </DataPoint.Value>
        <Text variant="body2" color={Token.color.greyTone50} textAlign="center">
          <FormattedMessage
            id="performance.metrics.highlights.average.label"
            values={{
              avg: averageFormatted,
            }}
            defaultMessage="Avg. {avg}"
          />
        </Text>
      </VStack>
      <Tooltip {...tooltip.getTargetProps()} {...tooltipProps}>
        <MetricDetails
          variant={variant}
          value={value}
          average={average}
          employeeName={employeeName}
          orgUnitName={orgUnitName}
          metricName={metricName}
          title={dataLabel}
          summary={summary}
        />
      </Tooltip>
    </Box>
  )
}

export const MetricHighlightSkeleton = (props: BoxProps) => (
  <Box p="s-16" radius={Token.radius.r16} bg={Token.color.widgetBackground} {...props}>
    <DataPointSkeleton />
  </Box>
)
