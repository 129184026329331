import { MetricDetailsVariants } from '@src/features/AiInsights/Performance/components/Metrics/common/MetricsHighlights/ComparableNumericMetric/constants'
import { formatPercentage, getTotalTimeFromMinutes } from '@src/utils/format'
import { numberWithCommas } from '@src/pages/Forms/QueryForm/components/Charts/helpers'

export const formatNumericValue = (
  value: number,
  variant: MetricDetailsVariants,
): string => {
  if (variant === MetricDetailsVariants.Percentage) {
    return formatPercentage(value)
  }

  if (variant === MetricDetailsVariants.Seconds) {
    return getTotalTimeFromMinutes(Math.round(value / 60))
  }

  return Number.isInteger(value) ? numberWithCommas(value) : String(value)
}
